


































































import { defineComponent, ref } from "@vue/composition-api";
import FindButton from "@monorepo/uikit/src/components/tableViews/FindButton.vue";
import ClearFilters from "@monorepo/uikit/src/components/tableViews/ClearFilters.vue";
import { defaultFilterPlaceholder } from "@monorepo/utils/src/constants";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import Autocomplete from "@monorepo/uikit/src/components/tableViews/Autocomplete.vue";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import Vue from "vue";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";
import useFilter from "@monorepo/utils/src/composables/useFilter";
import useAutocomplete from "@monorepo/utils/src/composables/useAutocomplete";
import useTemplateFilters from "@monorepo/utils/src/composables/useTemplateFilters";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";

export default defineComponent({
  components: {
    SelectFilter,
    FindButton,
    Autocomplete,
    ClearFilters,
  },
  props: {
    sectionName: {
      type: String,
      default: "",
    },
    viewUniqKey: {
      type: String,
      default: "",
    },
    filterItem: {
      type: Object,
      default: () => ({}),
    },
    setCurrentFilters: {
      type: Function,
      default: () => ({}),
    },
    autocompleteFiltersKeys: {
      type: Array,
    },
    findButtonTitle: {
      type: String,
    },
    isShowTemplates: {
      type: Boolean,
      default: true,
    },
    isShowAutoButton: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    currentDocumentView: {
      type: String,
      default: ViewMode.TABLE,
    },
  },
  data() {
    return {
      defaultFilterPlaceholder,
      educationIds,
      menu: false,
    };
  },
  computed: {
    isTableLoading(): boolean {
      return this.$store.getters[`${this.sectionName}/isTableLoading`];
    },
    fieldFilters(): Record<string, unknown> {
      return this.$store.getters[`${this.sectionName}/fieldFilters`];
    },
    searchTemplates(): ISearchTemplate[] {
      return this.$store.getters[`${this.sectionName}/searchTemplates`];
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleEnter() {},
    clearFilters() {
      this.$store.dispatch(`${this.sectionName}/clearFilters`);
    },
    refreshScroll() {
      this.$store.dispatch(`${this.sectionName}/refreshScroll`);
    },
    addFilter(value: Record<string, SORT_TYPE>) {
      this.$store.dispatch(`${this.sectionName}/addFilter`, value);
    },
    saveSearchTemplates(value: Partial<ISearchTemplate>) {
      this.$store.dispatch(`${this.sectionName}/saveSearchTemplates`, value);
    },
    clearEventList() {
      this.$store.dispatch(`${this.sectionName}/clearEventList`);
    },
    autocompleteAction(actionName: string): (value: string) => Promise<Record<string, unknown>[]> {
      return async (value: string) => {
        return await this.$store.dispatch(`${this.sectionName}/${actionName}`, value);
      };
    },
    saveSearchAsSelected(event: { event: string; filterItem?: { value: string } }) {
      if (event.filterItem?.value === "keyword" && event.event) {
        this.currentFilters[event.filterItem.value] = this.autocompleteItemsResultLists[event.filterItem.value][0];
      }
    },
    changeMenuItem(filterItem: Record<string, unknown>) {
      const menuItemsArr = (filterItem.menuItems as { value: string }[]).map((item) => item.value);
      if (menuItemsArr.map((value) => this.currentFilters[value]).every((item) => !item)) {
        Vue.nextTick(() => {
          this.currentFilters[menuItemsArr[0]] = true;
        });
      }
    },
  },
  setup(props, context) {
    const { root } = context;
    const currentFilters = ref((props.setCurrentFilters as () => Record<string, unknown>)());
    const isShowToggle = ref(false);
    const autocompleteItemsResultLists = ref({} as { [key: string]: Record<string, unknown>[] });
    const isLoadingAutocomplete = ref(false);
    useTemplateFilters(root, props.sectionName, currentFilters);
    const { filter, hiddenRowsStyleObj, openHiddenFilterOnStart, toggleOpenFilter, clearFiltersCb, refresh, updateFiltersDivHeight } = useFilter(
      context,
      props as {
        value: boolean;
        currentDocumentView: ViewMode;
      },
      props.sectionName,
      {},
      currentFilters,
      props.setCurrentFilters,
      [props.filterItem as any],
      ref([]),
      isShowToggle
    );
    const { getAutocompleteItems } = props.autocompleteFiltersKeys
      ? useAutocomplete(
          root,
          props.sectionName,
          currentFilters,
          autocompleteItemsResultLists,
          isLoadingAutocomplete,
          props.autocompleteFiltersKeys as string[]
        )
      : { getAutocompleteItems: null };

    return {
      filter,
      hiddenRowsStyleObj,
      openHiddenFilterOnStart,
      toggleOpenFilter,
      clearFiltersCb,
      refresh,
      updateFiltersDivHeight,
      currentFilters,
      isShowToggle,
      getAutocompleteItems,
      autocompleteItemsResultLists,
      isLoadingAutocomplete,
    };
  },
});
